import * as React from "react";
import { observer } from "mobx-react";
import { withStores } from "stores";
import { LinkGroupTabs } from "@shared-ui/retail-cross-links-link-group-tabs";
import { GeoLinkPilesProps, GeoLinkPilesFlexModuleResult } from "./typings";
import { LazyLoad } from "src/components/utility/LazyLoad/LazyLoad";

export const GeoLinkPiles = withStores(
  "flexModuleModelStore",
  "context",
  "uriContext"
)(
  observer((props: GeoLinkPilesProps) => {
    const { context, templateComponent, flexModuleModelStore, uriContext: uriContextStore } = props;

    /* istanbul ignore if */
    if (!context || !templateComponent || !flexModuleModelStore) {
      return null;
    }

    const { metadata } = templateComponent;
    const { id } = metadata;
    const model = flexModuleModelStore.getModel(id) as GeoLinkPilesFlexModuleResult | null;
    const uriContext = uriContextStore?.get();

    if (!model || !uriContext) {
      return null;
    }

    const { limit, filterLinksbyIndexation } = model;

    const inputs = {
      strategyName: "geolinkpiles",
      uriContext: uriContext as React.ComponentProps<typeof LinkGroupTabs>["inputs"]["uriContext"],
      parameters: [{ name: "filterLinksbyIndexation", value: filterLinksbyIndexation.toString() || "false" }],
      limit: limit || 6,
    };

    return (
      <section>
        <LazyLoad context={context}>
          <LinkGroupTabs inputs={inputs} />
        </LazyLoad>
      </section>
    );
  })
);

export default GeoLinkPiles;
